import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import trackEvent from '../components/analytics';
import Layout, {
  Player,
  ViewButtonPrevious,
  ViewChoices,
  ViewChoice,
  ShareButtons,
} from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/ba/voixoff3_BA.mp3';
import Background from '../images/home.jpg';
import Borne1 from '../images/Bornes-1.png';
import Borne2 from '../images/Bornes-2.png';
import Borne3 from '../images/Bornes-3.png';
import Borne4 from '../images/Bornes-4.png';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
`;

const ViewImg = styled.img`
  position: absolute;
  width: 30px;
  margin-top: 10px;
  margin-left: -2.5rem;
`;

const ViewIcon = styled.i`
  width: 30px;
  margin: 0 0.5rem;
  color: #4cb32f;
  font-size: 1.5rem;
`;

const Home = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO pathname="/ba/home" />
    <ViewArticle>
      <ViewChoices>
        <Link to="/ba/part-1">
          <ViewChoice
            onClick={() => {
              trackEvent('Menu Page', 'User went to module 1 - my income', 'yes');
            }}
          >
            <ViewImg src={Borne1} />
            {typeof window !== 'undefined' &&
              localStorage.getItem('Step1Complete') === 'yes' && (
                <ViewIcon className="fa fa-check" />
              )}
            Gérer les revenus de mon activité
          </ViewChoice>
        </Link>
        <Link to="/ba/part-2">
          <ViewChoice
            onClick={() => {
              trackEvent('Menu Page', 'User went to module 2 - my money', 'yes');
            }}
          >
            <ViewImg src={Borne2} />
            {typeof window !== 'undefined' &&
              localStorage.getItem('Step2Complete') === 'yes' && (
                <ViewIcon className="fa fa-check" />
              )}
            Gérer mon argent en bon père de famille
          </ViewChoice>
        </Link>
        <Link to="/ba/part-3">
          <ViewChoice
            onClick={() => {
              trackEvent(
                'Menu Page',
                'User went to module 3 - my activities',
                'yes',
              );
            }}
          >
            <ViewImg src={Borne3} />
            {typeof window !== 'undefined' &&
              localStorage.getItem('Step3Complete') === 'yes' && (
                <ViewIcon className="fa fa-check" />
              )}
            Mieux organiser mon activité
          </ViewChoice>
        </Link>
        <Link to="/ba/part-4">
          <ViewChoice
            onClick={() => {
              trackEvent('Menu Page', 'User went to module 4 - final quizz', 'yes');
            }}
          >
            <ViewImg src={Borne4} />
            {typeof window !== 'undefined' &&
              localStorage.getItem('Step4Complete') === 'yes' && (
                <ViewIcon className="fa fa-check" />
              )}
            Quizz final
          </ViewChoice>
        </Link>
      </ViewChoices>
    </ViewArticle>
    <Player sound={Sound} />
    <Link to="/ba">
      <ViewButtonPrevious color="warning">
        <i className="fa fa-chevron-left fa" />
      </ViewButtonPrevious>
    </Link>
    <ShareButtons />
  </Layout>
);

export default Home;
